import React, { useEffect, useRef, useState } from "react";
import './index.scss'
import { DataTable } from "primereact/datatable";
import { ContentTop } from "../UI/ContentTop";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { ContentFilter } from "../UI/ContentFilter";
import { useSelector } from "react-redux";
import { Column } from "primereact/column";
import { carMasterStatus, dateView, getRange } from "../../utills/functions";
import { createCarMasterApi, deleteCarMasterApi, editCarMasterApi, getAllCarMasterApi } from "../../http/carMaster";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { utils, writeFileXLSX } from "xlsx";
import { Tag } from "primereact/tag";
import { getAllTaskPriority } from "../../http/settings";

export const CarMaster = () => {
    const [loading, setLoading] = useState(true)
    const toast = useRef(null);
    const user = useSelector((state) => state.user.user);
    const users = useSelector((state) => state.user.allUsers)
    const [visible, setVisible] = useState(false)
    const [items, setItems] = useState([])
    const [dates, setDates] = useState([new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)]);
    const [company, setCompany] = useState(null)
    const [phone, setPhone] = useState(null)
    const [adress, setAdress] = useState(null)
    const [vin, setVin] = useState(null)
    const [comment, setComment] = useState(null)
    const [priorities, setPriorities] = useState(null)
    const [date, setDate] = useState(null)
    const [statusFilter, setStatusFilter] = useState("Все заявки")

    useEffect(() => {
        getAllCarMasterApi(getRange(dates), date, statusFilter)
        .then(res => setItems(res.data))
        .catch(e => alert(e))
        .finally(() => setLoading(false))
    }, [loading, dates, date, statusFilter])

    useEffect(() => {
        getAllTaskPriority()
        .then(res => setPriorities(res.data))
        .catch(e => alert(e))
        .finally(() => setLoading(false))
    }, [loading])

    const headerTable = (i) => {
        return (
            <>
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Заявки на выездного мастера</div>
                    <div className="content-top-app">
                        <Button onClick={() => setVisible(!visible)} label="Добавить" icon="pi pi-plus"/>
                    </div>
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-count">Количество: {items.length} шт.</div>

                <div className="content-filter-app">
                <Dropdown value={statusFilter} onChange={(e) => {
                        setStatusFilter(e.value)
                        setLoading(!loading)
                    }} options={[{name: "Все заявки"}, {name: "Новый"}, {name: "Выполнено"}, {name: "В сервис"}, {name: "Перенос"}, {name: "Отменено"}, {name: "Доставка в сервис"}, {name: "Доставка с сервиса"}].map(u => u.name)} optionLabel="name"
                    placeholder="Выбери опцию" className="w-full md:w-14rem" checkmark={true}  highlightOnSelect={false}/>

                <Calendar showButtonBar value={date} onChange={(e) => setDate(e.value)} dateFormat="dd/mm/yy" readOnlyInput hideOnRangeSelection style={{width: "9rem"}}/>
                <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" dateFormat="dd/mm/yy" readOnlyInput hideOnRangeSelection />
                    {user.role === "ADMIN" && (
                        <>
                        <Button onClick={getExcel} icon="pi pi-file-excel" style={{background: "green", height: "26px"}}/>
                        {/* <Button icon="pi pi-chart-line" style={{height: "26px"}}/> */}
                        </>
                    )}
                </div>
            </ContentFilter>
            </>
        )
    }

    const getExcel = () => {
        let arr = items.map((e, i) => (
            {
              "№": i + 1,
              "Дата добавления": new Date(e.createdAt),
              "Дата выполения": new Date(e.date),
              "Фирма": e.company,
              "Адрес": e.adress,
              "VIN": e.vin,
              "Неисправность": e.comment,
              "Статус": e.status
            }
          ))

          let wb = utils.book_new();
          let ws = utils.json_to_sheet(arr);
          utils.book_append_sheet(wb, ws, "Выездной мастер");
          writeFileXLSX(wb, "Выездной мастер.xlsx");
    }

    const allowEdit = (rowData) => {
        return rowData
    };

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;
        // new Date(newData.date.setHours(newData.date.getHours() + 3)).toISOString()
        try {
            setItems(_items);
            editCarMasterApi(newData.id, newData.masterId ? newData.masterId.id : null , newData.order, newData.order2, newData.date, newData.company, newData.adress, newData.phone, newData.vin, newData.comment, newData.priority, newData.status)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Заявка изменена!", life: 3000});
            setLoading(!loading)
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: e.response.data.message, life: 3000});
        }
    };

    const createItem = () => {
        try {
            createCarMasterApi(user.id, null, null, null, null, company, adress, phone, vin, comment, "Низкий", "Новый")
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Заявка добавлена!", life: 3000});

            setVisible(false)
            setLoading(!loading)
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: error, life: 3000});
        }
    }

    const deleteTable = (i) => {
        return <i onClick={() => {
            const accept = () => {
                try {
                  deleteCarMasterApi(i.id)
                  setLoading(!loading)
                } catch (error) {
                  alert(error)
                }
              };

              const reject = () => {
                  return
              };

              confirmDialog({
                message: 'Вы действительно хотите удалить заявку?',
                header: 'Удаление',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                position: 'center',
                accept,
                reject
            });

        }} className="pi pi-trash" style={{ fontSize: '1.3rem' }}></i>
    }

    const userView = (i) => {
        let item = users?.filter(q => q.id === i.managerId)

        if(item) return (
            <div style={{display: "flex", gap: ".4rem", flexDirection: "column"}}>
                {item[0]?.name && <div>{dateView(i.createdAt)} / {item[0]?.name}</div>}
            </div>
        )
    }

    const date1View = (i) => {
        return <div>{!i.date ? "" : new Date(i.date).toLocaleDateString("ru", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            })}</div>
    }

    const masterView = (i) => {
        let item = users?.filter(q => q.id === i.masterId)

        if(item) return (
            <div style={{display: "flex", gap: ".4rem", flexDirection: "column"}}>
                {item[0]?.name && <div>{item[0]?.name}</div>}
            </div>
        )
    }

    const statusView = (i) => {
        return <div className={`carmaster carmaster-${carMasterStatus(i.status)}`}>{i.status}</div>
    }

    const pickColor = (color) => {
        let item = priorities?.filter(i => i.name === color)
        if(item) return item[0]?.color
    }

    const priorityView = (i) => {
        return i.priority && <Tag style={{fontSize: "1.4rem", padding: ".2rem 1rem", backgroundColor: `#${pickColor(i.priority)}`}}>{i.priority}</Tag>
    }


    const orderEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const phoneEditor = (options) => {
        return <InputMask mask="99999999999" style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const adressEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const vinEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const companyEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const commentEditor = (options) => {
        return <InputTextarea type="textarea" style={{width: "100%", maxWidth: '100%', minWidth: "100%", fontSize: '14px'}} rows={5} value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const dateEditor = (options) => {
        return <Calendar showButtonBar dateFormat="dd/mm/yy" style={{width: "100%"}} value={new Date(options.value)} onChange={e => options.editorCallback(e.target.value)}/>
    }

    const masterEditor = (options) => {
        let item = users?.filter(q => {
           if(q.role === "CARMASTER" && q.status === "Работает") return q
        })

        return (
            <Dropdown
                value={options.value}
                options={item}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Master"
                optionLabel="name"
                showClear
                style={{width: "100%"}}
            />
        );
    }

    const priorityEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={priorities.map(e => e.name)}
                onChange={(e) => options.editorCallback(e.value)}
                optionLabel="name"
                placeholder="Приоритет"
                style={{width: "100%"}}
            />
        )
    }

    const statusEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={[{name: "Новый"}, {name: "Выполнено"}, {name: "В сервис"}, {name: "Перенос"}, {name: "Отменено"}, {name: "Доставка в сервис"}, {name: "Доставка с сервиса"}].map(i => i.name)}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Master"
                style={{width: "100%"}}
            />
        )
    }

    return (
        <div className="field">
            <Toast ref={toast} position="bottom-center"/>
            <ConfirmDialog />
            <DataTable editMode="row" onRowEditComplete={onRowEditComplete} dataKey="id" value={items} header={headerTable} showGridlines stripedRows selectionMode="single" scrollable  paginator rows={25} rowsPerPageOptions={[25, 50, 100]} tableStyle={{ minWidth: '45rem' }}>
                <Column header="Дата/Сотрудник" body={userView} style={{width: "11%"}}/>
                <Column editor={orderEditor} header="На ремонт" field="order" style={{ width: "6%", textAlign: 'center' }}/>
                <Column editor={orderEditor} header="С ремонта" field="order2" style={{ width: "6%", textAlign: 'center' }}/>
                <Column editor={dateEditor} header="Дата вып." body={date1View} field="date" style={{width: "7%", textAlign: 'center' }}/>
                <Column editor={masterEditor} header="Мастер" body={masterView} field="masterId"/>
                <Column editor={companyEditor} header="Фирма" field="company" style={{width: "8%", textAlign: 'center' }}/>
                <Column editor={phoneEditor} header="Номер телефона" field="phone" style={{width: "8%", textAlign: 'center' }}/>
                <Column editor={adressEditor} header="Адрес" field="adress" style={{width: "12%" }}/>
                <Column editor={vinEditor} header="VIN" field="vin" style={{width: "10%"}}/>
                <Column editor={commentEditor} header="Неисправность" field="comment" style={{width: "19%" }}/>
                <Column editor={priorityEditor} header="Приоритет" body={priorityView} field="priority" style={{width: "6%", textAlign: 'center' }}/>
                <Column editor={statusEditor} header="Статус" body={statusView} field="status" style={{width: "6%", textAlign: 'center' }}/>

                <Column rowEditor={allowEdit} headerStyle={{ width: '3%', minWidth: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                <Column body={deleteTable} header="" bodyStyle={{ textAlign: 'center' }} style={{ width: '3%', color: 'red' }}></Column>
            </DataTable>

            <Dialog style={{ width: '32rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={visible} onHide={() => {if (!visible) return; setVisible(false); }}>
                <div className="create">
                    <div className="create-row">
                        <label htmlFor="company" className="font-bold">
                            Фирма
                        </label>
                        <InputText value={company} onChange={e => setCompany(e.target.value)} id="company"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="phone" className="font-bold">
                            Номер телефона
                        </label>
                        <InputMask mask="99999999999" value={phone} onChange={e => setPhone(e.value)} id="phone" />
                    </div>

                    <div className="create-row">
                        <label htmlFor="adress" className="font-bold">
                            Адрес
                        </label>
                        <InputText value={adress} onChange={e => setAdress(e.target.value)} id="adress"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="vin" className="font-bold">
                            VIN
                        </label>
                        <InputText value={vin} onChange={e => setVin(e.target.value)} id="vin"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="comment" className="font-bold">
                            Неисправность
                        </label>
                        <InputTextarea id="comment" value={comment} onChange={e => setComment(e.target.value)} />
                    </div>

                    <Button onClick={createItem}>Добавить</Button>
                </div>
            </Dialog>
        </div>
    )
}