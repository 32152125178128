import { Tag } from "primereact/tag";
import { NavLink } from "react-router-dom";


const ir = (item, options) => (
  <NavLink key={item.id} to={item.url}>
    {item.label}
  </NavLink>
);

const irTask = (item, options) => (
  <NavLink key={item.id} to={item.url} style={{width: "100%", display: "flex"}}>
    {item.label}
    {item.badge !== 0 && <Tag value={item.badge} style={{marginLeft: "auto", display: "flex", alignItems: "center", justifyContent: "center", fontWeight: "bold", fontSize: "1.2rem", padding: ".1rem 1rem", background: "red"}}></Tag>}
  </NavLink>
);

const irRefund = (item, options) => (
  <NavLink key={item.id} to={item.url} style={{width: "100%", display: "flex"}}>
    {item.label}
    {item.badge !== 0 && <Tag value={item.badge} style={{marginLeft: "auto", display: "flex", alignItems: "center", justifyContent: "center", fontWeight: "bold", fontSize: "1.2rem", padding: ".1rem 1rem", background: "red"}}></Tag>}
  </NavLink>
);

export const linkAdmin = (pos, value, refund) => [
  {
    label: "Задачи",
    url: "/task",
    template: irTask,
    badge: value
  },
  // {
  //   label: "Сервис",
  //   url: "/service",
  //   template: ir,
  // },
  {
    label: "Выездной мастер",
    url: "/car-master",
    template: ir,
  },
  // ((pos === "Руководитель" || pos === "Администратор" || pos === "Менеджер юр. лиц" || pos === "Бухгалтер") && {
  //   label: "Заявки на технику",
  //   url: "/order-tech",
  //   template: ir,
  // }),
  ((pos === "Руководитель" || pos === "Администратор" || pos === "Бухгалтер") && {
    label: "*Ежемесячные отчеты",
    items: [
      {
        label: "Фин. отчет",
        template: ir,
        url: "/report/fin",
      },
      {
        label: "Отчет по оплатам фирм",
        template: ir,
        url: "/report/company",
      },
      {
        label: "Статистика менеджеров",
        template: ir,
        url: "/report/statistics",
      },
      {
        label: "Техника, Розница",
        template: ir,
        url: "/report/info",
      },
      {
        label: "Анализ Колл-центр",
        template: ir,
        url: "/report/analiz"
      }
      // {
      //   label: "Сервис",
      //   template: ir,
      //   url: "/report/service",
      // },
    ]
  }),
  {
    label: "Информация",
    items: [
      {
        label: "Прайс лист",
        template: ir,
        url: "/information/price",
      },
      {
        label: "Контакты",
        template: ir,
        url: "/information/contacts",
      },
      {
        label: "Акции/Скидки",
        template: ir,
        url: "/information/sales",
      },
      {
        label: "Справочник",
        template: ir,
        url: "/information/guide",
      },
    ]
  },
  {
    label: "Таблица менеджера",
    items: [

      {
        label: "Заказы",
        template: ir,
        url: "/manager-tables/orders",
      },
      {
        label: "Продления",
        template: ir,
        url: "/manager-tables/prodlenie",
      },
      {
        label: "Задолженности",
        template: ir,
        url: "/manager-tables/debt",
      },
      {
        label: "Просроченные",
        template: ir,
        url: "/manager-tables/overdue",
      },
    ],
  },
  {
    label: "Работа с компаниями",
    items: [
      {
        label: "Счета на оплату",
        template: ir,
        url: "/w-company/payments",
      },
      {
        label: "Нужно выставить счёт",
        template: ir,
        url: "/w-company/need-payment",
      },
      {
        label: "Нужно сделать акт",
        template: ir,
        url: "/w-company/need-act",
      },
      {
        label: "Документы",
        template: ir,
        url: "/w-company/documents",
      },
    ],
  },
  {
    label: "Компании",
    template: ir,
    url: "/company",
  },
  {
    label: "Идеи на развитие",
    url: "/ideas",
    template: ir,
  },
  {
    label: "Комментарии клиентов",
    url: "/comments-client",
    template: ir,
  },
  {
    label: "Возвраты",
    url: "/refunds",
    template: irRefund,
    badge: refund
  },
];

export const linkManager = (value, refund) => [
  {
    label: "Задачи",
    url: "/task",
    template: irTask,
    badge: value
  },
  // {
  //   label: "Сервис",
  //   url: "/service",
  //   template: ir,
  // },
  {
    label: "Выездной мастер",
    url: "/car-master",
    template: ir,
  },
  {
    label: "Информация",
    items: [
      {
        label: "Прайс лист",
        template: ir,
        url: "/information/price",
      },
      {
        label: "Контакты",
        template: ir,
        url: "/information/contacts",
      },
      {
        label: "Акции/Скидки",
        template: ir,
        url: "/information/sales",
      },
      {
        label: "Справочник",
        template: ir,
        url: "/information/guide",
      },
    ]
  },
  {
    label: "Таблица менеджера",
    items: [
      {
        label: "Заказы",
        template: ir,
        url: "/manager-tables/orders",
      },
      {
        label: "Продления",
        template: ir,
        url: "/manager-tables/prodlenie",
      },
      {
        label: "Задолженности",
        template: ir,
        url: "/manager-tables/debt",
      },
      {
        label: "Просроченные",
        template: ir,
        url: "/manager-tables/overdue",
      },
    ],
  },
  {
    label: "Работа с компаниями",
    items: [
      {
        label: "Счета на оплату",
        template: ir,
        url: "/w-company/payments",
      },
      {
        label: "Нужно выставить счёт",
        template: ir,
        url: "/w-company/need-payment",
      },
      {
        label: "Нужно сделать акт",
        template: ir,
        url: "/w-company/need-act",
      },
      {
        label: "Документы",
        template: ir,
        url: "/w-company/documents",
      },
    ],
  },
  {
    label: "Компании",
    template: ir,
    url: "/company",
  },
  {
    label: "Идеи на развитие",
    url: "/ideas",
    template: ir,
  },
  {
    label: "Комментарии клиентов",
    url: "/comments-client",
    template: ir,
  },
  {
    label: "Возвраты",
    url: "/refunds",
    template: irRefund,
    badge: refund
  },
];
