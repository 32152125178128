import React, { useState } from "react";
import './index.scss'
import { DataTable } from "primereact/datatable";
import { ContentFilter } from "../UI/ContentFilter";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { EmployerInfo } from "./employerInfo";

export const ReportFinanceEmployer = () => {
    const [loading, setLoading] = useState(true)
    const [employerModal, setEmployerModal] = useState(false)

    const headerTable = () => {
        return (
            <>
            <ContentFilter>
                <div className="content-filter-app">
                    <Button onClick={() => setEmployerModal(!employerModal)}>Сотрудники</Button>
                </div>
            </ContentFilter>
            </>
        )
    }

    return (
        <>
            <DataTable header={headerTable}>

            </DataTable>

            <Dialog style={{ width: '120rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={employerModal} onHide={() => {if (!employerModal) return; setEmployerModal(false); }}>
                <EmployerInfo/>
            </Dialog>
        </>

    )
}