import { $host } from "./index";

export const createEmployerApi = async (name, position, shedule, format, salary, status) => {
    const { data } = await $host.post("api/employer", { name, position, shedule, format, salary, status });
    return data;
  };

  export const getAllEmployerApi = async () => {
    const data = await $host.get("api/employer");
    return data;
  };

  export const deleteEmployerApi = async (id) => {
    const data = await $host.delete(`api/employer/${id}`);
    return data;
  };

  export const editEmployerApi = async (id, name, position, shedule, format, salary, status) => {
    const data = await $host.put(`api/employer/${id}`, {name, position, shedule, format, salary, status});
    return data;
  };