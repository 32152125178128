import React, { useEffect, useRef, useState } from "react";
import './index.scss'
import { ContentTop } from "../UI/ContentTop";
import { ContentFilter } from "../UI/ContentFilter";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import generatePDF from "react-to-pdf";
import { Dialog } from "primereact/dialog";
import { createAnalizCallCenterApi, createAnalizCallCenterManagerApi, deleteAnalizCallCenterManagerApi, editAnalizCallCenterManagerApi, getAllAnalizCallCenterApi, getAllAnalizManagersApi } from "../../http/analizCallCenter";
import { getRange } from "../../utills/functions";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Chart } from "primereact/chart";

export const AnalizManager = () => {
    const [loading, setLoading] = useState(true)
    const users = useSelector((state) => state.user.allUsers)
    const [items, setItems] = useState([])
    const [managerItems, setManagerItems] = useState([])
    const [itemId, setItemId] = useState(null)
    const [dates, setDates] = useState([new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)]);
    const targetRef = useRef();
    const toast = useRef(null);
    const [visible, setVisible] = useState(false)
    const [visibleEdit, setVisibleEdit] = useState(false)
    const [visibleStat, setVisibleStat] = useState(false)
    const [visibleLine, setVisibleLine] = useState(false)
    const [date, setDate] = useState(null)
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [chartData1, setChartData1] = useState({});
    const [chartOptions1, setChartOptions1] = useState({});
    const [chartData2, setChartData2] = useState({});
    const [chartOptions2, setChartOptions2] = useState({});
    const [vhodprin, setVhodprin] = useState(null)
    const [vhodprop, setVhodprop] = useState(null)
    const [ishodprin, setIshodprin] = useState(null)
    const [ishodprop, setIshodprop] = useState(null)
    const [stats, setStats] = useState([])

    useEffect(() => {
        getAllAnalizCallCenterApi(getRange(dates))
        .then(res => setItems(res.data))
        .catch(e => alert(e))
        .finally(() => setLoading(false))
    }, [loading, dates])

    useEffect(() => {
       if(itemId) getAllAnalizManagersApi(itemId)
        .then(res => setManagerItems(res.data))
        .catch(e => alert(e))
        .finally(() => setLoading(false))
    }, [itemId, loading])

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        setVhodprin(items.map(i => i.manager.reduce((a,b) => a + b.vvprin, 0)).reduce((n,m) => n + m, 0))
        setVhodprop(items.map(i => i.manager.reduce((a,b) => a + b.vvprop, 0)).reduce((n,m) => n + m, 0))

        const data = {
            labels: ['Принятые', 'Пропущенные'],
            datasets: [
                {
                    data: [vhodprin, vhodprop],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--green-700'),
                        documentStyle.getPropertyValue('--red-700'),
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--green-400'),
                        documentStyle.getPropertyValue('--red-400'),
                    ]
                }
            ]
        };
        const options = {
            cutout: '60%'
        };

        setChartData(data);
        setChartOptions(options);
    }, [items, vhodprin, vhodprop]);

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        setIshodprin(items.map(i => i.manager.reduce((a,b) => a + b.ivusp, 0)).reduce((n,m) => n + m, 0))
        setIshodprop(items.map(i => i.manager.reduce((a,b) => a + b.ivpot, 0)).reduce((n,m) => n + m, 0))

        const data = {
            labels: ['Успешные', 'Потерянные'],
            datasets: [
                {
                    data: [ishodprin, ishodprop],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--green-700'),
                        documentStyle.getPropertyValue('--red-700'),
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--green-400'),
                        documentStyle.getPropertyValue('--red-400'),
                    ]
                }
            ]
        };
        const options = {
            cutout: '60%'
        };

        setChartData1(data);
        setChartOptions1(options);
    }, [items, ishodprin, ishodprop]);

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: items.map(i => {return i.date.split('T', 1)[0]}).reverse(),
            datasets: [
                {
                    label: 'Принятые входящие',
                    data: items.map(i => i.manager.reduce((a,b) => a + b.vvprin, 0)).reverse(),
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--green-700'),
                    tension: 0.2
                },
                {
                    label: 'Пропущенные входящие',
                    data: items.map(i => i.manager.reduce((a,b) => a + b.vvprop, 0)).reverse(),
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--red-700'),
                    tension: 0.2
                },
                {
                    label: 'Исходящие успешные',
                    data: items.map(i => i.manager.reduce((a,b) => a + b.ivusp, 0)).reverse(),
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--blue-700'),
                    tension: 0.2
                },
                {
                    label: 'Исходящие потерянные',
                    data: items.map(i => i.manager.reduce((a,b) => a + b.ivpot, 0)).reverse(),
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--yellow-700'),
                    tension: 0.2
                },
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData2(data);
        setChartOptions2(options);
    }, [items]);

    useEffect(() => {
        const res = {}
        items.forEach(({ manager, ...dates }) => {
            manager.forEach(({ manager, ...dates }) => {
                const item = manager ? res[manager?.name] || {id: manager?.id, name: manager?.name} : {id: 0, name: "Пусто"};

               item['vvprin']  = (item['vvprin'] || 0) + Number(dates.vvprin)
               item['vvprop']  = (item['vvprop'] || 0) + Number(dates.vvprop)
               item['vzprop']  = (item['vzprop'] || 0) + Number(dates.vzprop)
               item['ivusp']  = (item['ivusp'] || 0) + Number(dates.ivusp)
               item['ivpot']  = (item['ivpot'] || 0) + Number(dates.ivpot)

               res[manager?.name] = { ...item };
            })
        })

        setStats(Object.values(res))
    }, [items])

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Дата" rowSpan={3} />
                <Column header="Менеджер" rowSpan={3} />
            </Row>
            <Row>
                <Column header="Входящие звонки" colSpan={6} />
                <Column header="Исходящие звонки" colSpan={5} />
                <Column header="" colSpan={2}/>
            </Row>
            <Row>
                <Column header="Прин. Выз." field="" />
                <Column header="Проп. Выз." field="" />
                <Column header="Проп. Зв." field="" />
                <Column header="Общ. вр. разг." field="" />
                <Column header="Ср. вр. отв." field="" />
                <Column header="Проп. / %" field="" />

                <Column header="Усп." field="" />
                <Column header="Пот." field="" />
                <Column header="Общ. вр. разг." field="" />
                <Column header="Ср. вр. разг." field="" />
                <Column header="Недозв. / %" field="" />

                <Column header="" field="" />
                <Column header="" field="" />
            </Row>
        </ColumnGroup>
    );

    const addManager = () => {
        return <i onClick={createManager} className="pi pi-plus" style={{ fontSize: '2rem', cursor: "pointer"}}></i>
    }

    const createManager = () => {
        try {
            if(!itemId) return
            createAnalizCallCenterManagerApi(null, null, null, null, null, null, null, null, null, null, itemId)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Запись добавлена!", life: 3000});

            setVisible(false)
            setLoading(!loading)
            setDate(null)
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: error, life: 3000});
        }
    }

    const headerGroup1 = (
        <ColumnGroup>
            <Row>
                <Column header="Менеджер" rowSpan={3} />
            </Row>
            <Row>
                <Column header="Входящие звонки" colSpan={5} />
                <Column header="Исходящие звонки" colSpan={4} />
                <Column header={addManager} colSpan={2}/>
            </Row>
            <Row>
                <Column header="Прин." field="" />
                <Column header="Проп." field="" />
                <Column header="Проп. Зв." field="" />
                <Column header="Общ. вр. разг." field="" />
                <Column header="Ср. вр. отв." field="" />

                <Column header="Усп." field="" />
                <Column header="Пот." field="" />
                <Column header="Общ. вр. разг." field="" />
                <Column header="Ср. вр. разг." field="" />

                <Column header="" field="" />
            </Row>
        </ColumnGroup>
    );

    const headerGroup2 = (
        <ColumnGroup>
            <Row>
                <Column header="Менеджер" rowSpan={3} />
            </Row>
            <Row>
                <Column header="Входящие звонки" colSpan={3} />
                <Column header="Исходящие звонки" colSpan={2} />
            </Row>
            <Row>
                <Column header="Прин." field="" />
                <Column header="Проп." field="" />
                <Column header="Проп. Зв." field="" />

                <Column header="Усп." field="" />
                <Column header="Пот." field="" />
            </Row>
        </ColumnGroup>
    );

    const createItem = () => {
        try {
            if(!date) return
            createAnalizCallCenterApi(new Date(date.setHours(date.getHours() + 3)).toISOString())
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Запись добавлена!", life: 3000});

            setVisible(false)
            setLoading(!loading)
            setDate(null)
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: error, life: 3000});
        }
    }

    const deleteTable = (i) => {
        return <i onClick={() => {
            const accept = () => {
                try {
                //   deleteCarMasterApi(i.id)
                  setLoading(!loading)
                } catch (error) {
                  alert(error)
                }
            };

            const reject = () => {
                return
            };

            confirmDialog({
                message: 'Вы действительно хотите удалить запись?',
                header: 'Удаление',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                position: 'center',
                accept,
                reject
            });

            }} className="pi pi-trash" style={{ fontSize: '1.3rem' }}></i>
    }

    const deleteTableManager = (i) => {
        return <i onClick={() => {
            const accept = () => {
                try {
                  deleteAnalizCallCenterManagerApi(i.id)
                  setLoading(!loading)
                } catch (error) {
                  alert(error)
                }
            };

            const reject = () => {
                return
            };

            confirmDialog({
                message: 'Вы действительно хотите удалить запись?',
                header: 'Удаление',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                position: 'center',
                accept,
                reject
            });

            }} className="pi pi-trash" style={{ fontSize: '1.3rem' }}></i>
    }


    const dateView = (i) => {
        return <div>{!i.date ? "" : new Date(i.date).toLocaleDateString("ru", {
            day: "numeric",
            month: "long",
            year: "numeric",
            })}</div>
    }

    const editItem = (i) => {
        return <i onClick={() => {
            setItemId(i.id)
            setVisibleEdit(!visibleEdit)
        }} className="pi pi-file-edit" style={{ fontSize: '1.5rem' }}></i>
    }

    const onMainCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;

        switch (field) {
            case "manager":
                if (newValue === null || newValue instanceof Object) {
                    rowData[field] = newValue

                    try {
                        editAnalizCallCenterManagerApi(rowData.id, newValue, rowData.vvprin, rowData.vvprop, rowData.vzprop, rowData.vvsvr, rowData.vvsvo, rowData.ivusp, rowData.ivpot, rowData.ivsvr, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        toast.current.show({severity:'error', summary: 'Error', detail: e.response.data.message, life: 3000});
                    }
                } else if(newValue === "" || newValue === null || newValue === undefined) {
                    rowData[field] = null
                    try {
                        editAnalizCallCenterManagerApi(rowData.id, null, rowData.vvprin, rowData.vvprop, rowData.vzprop, rowData.vvsvr, rowData.vvsvo, rowData.ivusp, rowData.ivpot, rowData.ivsvr, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        alert(error)
                    }
                } else event.preventDefault();
            break;

            case "vvprin":
                if (newValue === null || newValue.length > 0) {
                    rowData[field] = newValue

                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, newValue, rowData.vvprop, rowData.vzprop, rowData.vvsvr, rowData.vvsvo, rowData.ivusp, rowData.ivpot, rowData.ivsvr, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        toast.current.show({severity:'error', summary: 'Error', detail: e.response.data.message, life: 3000});
                    }
                } else if(newValue === "" || newValue === null || newValue.length === 0) {
                    rowData[field] = null
                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, null, rowData.vvprop, rowData.vzprop, rowData.vvsvr, rowData.vvsvo, rowData.ivusp, rowData.ivpot, rowData.ivsvr, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        alert(error)
                    }
                } else event.preventDefault();
            break;

            case "vvprop":
                if (newValue === null || newValue.length > 0) {
                    rowData[field] = newValue

                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, rowData.vvprin, newValue, rowData.vzprop, rowData.vvsvr, rowData.vvsvo, rowData.ivusp, rowData.ivpot, rowData.ivsvr, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        toast.current.show({severity:'error', summary: 'Error', detail: e.response.data.message, life: 3000});
                    }
                } else if(newValue === "" || newValue === null || newValue.length === 0) {
                    rowData[field] = null
                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, rowData.vvprin, null, rowData.vzprop, rowData.vvsvr, rowData.vvsvo, rowData.ivusp, rowData.ivpot, rowData.ivsvr, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        alert(error)
                    }
                } else event.preventDefault();
            break;

            case "vzprop":
                if (newValue === null || newValue.length > 0) {
                    rowData[field] = newValue

                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, rowData.vvprin, rowData.vvprop, newValue, rowData.vvsvr, rowData.vvsvo, rowData.ivusp, rowData.ivpot, rowData.ivsvr, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        toast.current.show({severity:'error', summary: 'Error', detail: e.response.data.message, life: 3000});
                    }
                } else if(newValue === "" || newValue === null || newValue.length === 0) {
                    rowData[field] = null
                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, rowData.vvprin, rowData.vvprop, null, rowData.vvsvr, rowData.vvsvo, rowData.ivusp, rowData.ivpot, rowData.ivsvr, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        alert(error)
                    }
                } else event.preventDefault();
            break;

            case "ivusp":
                if (newValue === null || newValue.length > 0) {
                    rowData[field] = newValue

                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, rowData.vvprin, rowData.vvprop, rowData.vzprop, rowData.vvsvr, rowData.vvsvo, newValue, rowData.ivpot, rowData.ivsvr, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        toast.current.show({severity:'error', summary: 'Error', detail: e.response.data.message, life: 3000});
                    }
                } else if(newValue === "" || newValue === null || newValue.length === 0) {
                    rowData[field] = null
                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, rowData.vvprin, rowData.vvprop, rowData.vzprop, rowData.vvsvr, rowData.vvsvo, null, rowData.ivpot, rowData.ivsvr, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        alert(error)
                    }
                } else event.preventDefault();
            break;

            case "ivpot":
                if (newValue === null || newValue.length > 0) {
                    rowData[field] = newValue

                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, rowData.vvprin, rowData.vvprop, rowData.vzprop, rowData.vvsvr, rowData.vvsvo, rowData.ivusp, newValue, rowData.ivsvr, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        toast.current.show({severity:'error', summary: 'Error', detail: e.response.data.message, life: 3000});
                    }
                } else if(newValue === "" || newValue === null || newValue.length === 0) {
                    rowData[field] = null
                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, rowData.vvprin, rowData.vvprop, rowData.vzprop, rowData.vvsvr, rowData.vvsvo, rowData.ivusp, null, rowData.ivsvr, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        alert(error)
                    }
                } else event.preventDefault();
            break;

            case "vvsvr":
                if (newValue === null || newValue.length > 0) {
                    rowData[field] = newValue

                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, rowData.vvprin, rowData.vvprop, rowData.vzprop, newValue, rowData.vvsvo, rowData.ivusp, rowData.ivpot, rowData.ivsvr, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        toast.current.show({severity:'error', summary: 'Error', detail: e.response.data.message, life: 3000});
                    }
                } else if(newValue === "" || newValue === null || newValue.length === 0) {
                    rowData[field] = null
                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, rowData.vvprin, rowData.vvprop, rowData.vzprop, null, rowData.vvsvo, rowData.ivusp, rowData.ivpot, rowData.ivsvr, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        alert(error)
                    }
                } else event.preventDefault();
            break;

            case "vvsvo":
                if (newValue === null || newValue.length > 0) {
                    rowData[field] = newValue

                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, rowData.vvprin, rowData.vvprop, rowData.vzprop, rowData.vvsvr, newValue, rowData.ivusp, rowData.ivpot, rowData.ivsvr, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        toast.current.show({severity:'error', summary: 'Error', detail: e.response.data.message, life: 3000});
                    }
                } else if(newValue === "" || newValue === null || newValue.length === 0) {
                    rowData[field] = null
                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, rowData.vvprin, rowData.vvprop, rowData.vzprop, rowData.vvsvr, null, rowData.ivusp, rowData.ivpot, rowData.ivsvr, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        alert(error)
                    }
                } else event.preventDefault();
            break;

            case "ivsvr":
                if (newValue === null || newValue.length > 0) {
                    rowData[field] = newValue

                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, rowData.vvprin, rowData.vvprop, rowData.vzprop, rowData.vvsvr, rowData.vvsvo, rowData.ivusp, rowData.ivpot, newValue, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        toast.current.show({severity:'error', summary: 'Error', detail: e.response.data.message, life: 3000});
                    }
                } else if(newValue === "" || newValue === null || newValue.length === 0) {
                    rowData[field] = null
                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, rowData.vvprin, rowData.vvprop, rowData.vzprop, rowData.vvsvr, rowData.vvsvo, rowData.ivusp, rowData.ivpot, null, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        alert(error)
                    }
                } else event.preventDefault();
            break;

            case "ivsvo":
                if (newValue === null || newValue.length > 0) {
                    rowData[field] = newValue

                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, rowData.vvprin, rowData.vvprop, rowData.vzprop, rowData.vvsvr, rowData.vvsvo, rowData.ivusp, rowData.ivpot, rowData.ivsvr, rowData.ivsvo)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        toast.current.show({severity:'error', summary: 'Error', detail: e.response.data.message, life: 3000});
                    }
                } else if(newValue === "" || newValue === null || newValue.length === 0) {
                    rowData[field] = null
                    try {
                        editAnalizCallCenterManagerApi(rowData.id, rowData.manager, rowData.vvprin, rowData.vvprop, rowData.vzprop, rowData.vvsvr, rowData.vvsvo, rowData.ivusp, rowData.ivpot, rowData.ivsvr, null)
                        toast.current.show({severity:'success', summary: 'Success!', detail: "Success!", life: 3000});
                        setLoading(!loading)
                    } catch (error) {
                        alert(error)
                    }
                } else event.preventDefault();
            break;
        }
    }

    const managerView = (i) => {
        return <div>{i.manager?.name}</div>

    }

    const managerEditor = (options) => {
        let item = users?.filter(q => {
           if(q.role === "MANAGER" && q.status === "Работает" && (q.position === "Оператор колл-центра" || q.position === "Менеджер юр. лиц")) return q
        })

        return (
            <Dropdown
                value={options.value}
                options={item}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Manager"
                optionLabel="name"
                showClear
                style={{width: "100%"}}
            />
        );
    }

    const vvprinEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const vvpropEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const vzpropEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const ivuspEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const ivpotEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const vvsvrEditor = (options) => {
        return <InputMask type="text" style={{fontSize: "14px", width: "100%"}} value={options.value} onChange={(e) => options.editorCallback(e.target.value)} mask="99:99:99" placeholder="00:00:00" slotChar="hh/mm/ss"/>
    }

    const vvsvoEditor = (options) => {
        return <InputMask type="text" style={{fontSize: "14px", width: "100%"}} value={options.value} onChange={(e) => options.editorCallback(e.target.value)} mask="99:99:99" placeholder="00:00:00" slotChar="hh/mm/ss"/>
    }

    const ivsvrEditor = (options) => {
        return <InputMask type="text" style={{fontSize: "14px", width: "100%"}} value={options.value} onChange={(e) => options.editorCallback(e.target.value)} mask="99:99:99" placeholder="00:00:00" slotChar="hh/mm/ss"/>
    }

    const ivsvoEditor = (options) => {
        return <InputMask type="text" style={{fontSize: "14px", width: "100%"}} value={options.value} onChange={(e) => options.editorCallback(e.target.value)} mask="99:99:99" placeholder="00:00:00" slotChar="hh/mm/ss"/>
    }

    const managerOwnView = (i) => {
        return <div>{i.manager.map(q => {
            return (
                <div className="manager_view">{q?.manager?.name}</div>
            )
        })}</div>
    }

    const vvprinView = (i) => {
        return <div>{i.manager.map(q => {
            return (
                <div className="manager_view">{q?.vvprin}</div>
            )
        })}</div>
    }

    const vvpropView = (i) => {
        return <div>{i.manager.map(q => {
            return (
                <div className="manager_view">{q?.vvprop}</div>
            )
        })}</div>
    }

    const vzpropView = (i) => {
        return <div>{i.manager.map(q => {
            return (
                <div className="manager_view">{q?.vzprop}</div>
            )
        })}</div>
    }

    const vvsvrView = (i) => {
        return <div>{i.manager.map(q => {
            return (
                <div className="manager_view">{q?.vvsvr}</div>
            )
        })}</div>
    }

    const ivuspView = (i) => {
        return <div>{i.manager.map(q => {
            return (
                <div className="manager_view">{q?.ivusp}</div>
            )
        })}</div>
    }

    const ivpotView = (i) => {
        return <div>{i.manager.map(q => {
            return (
                <div className="manager_view">{q?.ivpot}</div>
            )
        })}</div>
    }

    const vvsvoView = (i) => {
        return <div>{i.manager.map(q => {
            return (
                <div className="manager_view">{q?.vvsvo}</div>
            )
        })}</div>
    }

    const ivsvrView = (i) => {
        return <div>{i.manager.map(q => {
            return (
                <div className="manager_view">{q?.ivsvr}</div>
            )
        })}</div>
    }

    const ivsvoView = (i) => {
        return <div>{i.manager.map(q => {
            return (
                <div className="manager_view">{q?.ivsvo}</div>
            )
        })}</div>
    }

    const propView = (i) => {
        return <div>{i.manager.map(q => {
            let prop = q?.vvprin ? ((q?.vvprop / (q?.vvprop + q?.vvprin)) * 100).toFixed(2) : 0
            return (
                <div className="manager_view">{prop ? prop + "%" : ""}</div>
            )
        })}</div>
    }

    const potView = (i) => {
        return <div>{i.manager.map(q => {
            let prop = q?.ivusp ? ((q?.ivpot / (q?.ivpot + q?.ivusp)) * 100).toFixed(2) : 0

            return (
                <div className="manager_view">{prop ? prop + "%" : ""}</div>
            )
        })}</div>
    }

    return (
        <div className="field">
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Анализ Колл-центр</div>
                    <div className="content-top-app">
                        <Button onClick={() => setVisible(!visible)} label="Добавить" icon="pi pi-plus"/>
                    </div>
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-app">
                    <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" dateFormat="dd/mm/yy" readOnlyInput hideOnRangeSelection />
                    <Button type="button" icon="pi pi-file-pdf" severity="warning" onClick={() => generatePDF(targetRef, {filename: 'payments.pdf'})} data-pr-tooltip="PDF" />
                    <Button type="button" icon="pi pi-chart-bar" severity="help" onClick={() => setVisibleStat(!visibleStat)}/>
                    <Button type="button" icon="pi pi-chart-line" severity="success" onClick={() => setVisibleLine(!visibleLine)}/>
                </div>
            </ContentFilter>

            <ConfirmDialog />
            <Toast ref={toast} position="bottom-center"/>
            <div ref={targetRef} className="tabless">
                <DataTable headerColumnGroup={headerGroup} editMode="row" dataKey="id" value={items}  showGridlines stripedRows selectionMode="single" scrollable  tableStyle={{ minWidth: '100%' }}>
                    <Column body={dateView} field="date" style={{width: "10%"}}/>
                    <Column body={managerOwnView} field="" style={{width: "10%", padding: "0"}}/>
                    <Column body={vvprinView} field="" style={{width: "6%", padding: "0", textAlign: "center", fontWeight: "700"}}/>
                    <Column body={vvpropView} field="" style={{width: "6%", padding: "0", textAlign: "center", fontWeight: "700"}}/>
                    <Column body={vzpropView} field="" style={{width: "6%", padding: "0", textAlign: "center", fontWeight: "700"}}/>
                    <Column body={vvsvrView} field="" style={{width: "6%", padding: "0", textAlign: "center", fontWeight: "700"}}/>
                    <Column body={vvsvoView} field="" style={{width: "6%", padding: "0", textAlign: "center", fontWeight: "700"}}/>
                    <Column body={propView} field="" style={{width: "6%", padding: "0", textAlign: "center", fontWeight: "700"}}/>
                    <Column body={ivuspView} field="" style={{width: "6%", padding: "0", textAlign: "center", fontWeight: "700"}}/>
                    <Column body={ivpotView} field="" style={{width: "6%", padding: "0", textAlign: "center", fontWeight: "700"}}/>
                    <Column body={ivsvrView} field="" style={{width: "6%", padding: "0", textAlign: "center", fontWeight: "700"}}/>
                    <Column body={ivsvoView} field="" style={{width: "6%", padding: "0", textAlign: "center", fontWeight: "700"}}/>
                    <Column body={potView} field="" style={{width: "6%", padding: "0", textAlign: "center", fontWeight: "700"}}/>
                    <Column body={editItem} field="" bodyStyle={{ textAlign: 'center' }} style={{width: "2%", color: "blue"}}/>
                    <Column body={null} header="" bodyStyle={{ textAlign: 'center' }} style={{ width: '2%', color: 'red' }}></Column>
                </DataTable>
            </div>

            <Dialog style={{ width: '32rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={visible} onHide={() => {if (!visible) return; setVisible(false); }}>
                <div className="create">
                    <div className="create-row">
                        <label htmlFor="order" className="font-bold">
                            Дата
                        </label>
                        <Calendar
                            id="buttondisplay"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            dateFormat="dd MM yy"
                        />
                    </div>

                    <Button onClick={createItem}>Добавить</Button>
                </div>
            </Dialog>

            <Dialog style={{ width: '140rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={visibleStat} onHide={() => {if (!visibleStat) return; setVisibleStat(false); }}>
                <div className="grafics">
                    <div className="grafics_vhod">
                        <Chart type="doughnut" data={chartData} options={chartOptions} style={{width: "30rem"}} className="w-full md:w-30rem"/>
                        <div className="grafics_info">
                            <h1>Входящие вызовы</h1>
                            <h3>Всего вызовов: {vhodprin + vhodprop}</h3>
                            <h3>Принятые вызовы: {vhodprin}</h3>
                            <h3>Пропущенные вызовы: {vhodprop}</h3>
                        </div>
                    </div>
                    <div className="grafics_ishod">
                        <Chart type="doughnut" data={chartData1} options={chartOptions1} style={{width: "30rem"}} className="w-full md:w-30rem" />
                        <div className="grafics_info">
                            <h1>Исходящие вызовы</h1>
                            <h3>Всего вызовов: {ishodprin + ishodprop}</h3>
                            <h3>Успешные вызовы: {ishodprin}</h3>
                            <h3>Потерянные вызовы: {ishodprop}</h3>
                        </div>
                    </div>
                </div>
                <DataTable headerColumnGroup={headerGroup2} dataKey="id" value={stats}  showGridlines stripedRows selectionMode="single" scrollable  tableStyle={{ minWidth: '100%' }}>
                    <Column field="name"/>
                    <Column field="vvprin"/>
                    <Column field="vvprop"/>
                    <Column field="vzprop"/>
                    <Column field="ivusp"/>
                    <Column field="ivpot"/>
                </DataTable>
            </Dialog>

            <Dialog style={{ width: '140rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={visibleLine} onHide={() => {if (!visibleLine) return; setVisibleLine(false); }}>
                    <Chart type="line" data={chartData2} options={chartOptions2} style={{height: "50rem"}} />
            </Dialog>

            <Dialog style={{ width: '140rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={visibleEdit} onHide={() => {if (!visibleEdit) return; setVisibleEdit(false); setItemId(null); setManagerItems([])}}>
                <DataTable headerColumnGroup={headerGroup1} editMode="cell" dataKey="id" value={managerItems}  showGridlines stripedRows selectionMode="single" scrollable  tableStyle={{ minWidth: '100%' }}>
                    <Column editor={managerEditor} onCellEditComplete={onMainCellEditComplete} body={managerView} field="manager" style={{width: "10%"}}/>
                    <Column editor={vvprinEditor} onCellEditComplete={onMainCellEditComplete} body={null} field="vvprin" style={{width: "5%"}}/>
                    <Column editor={vvpropEditor} onCellEditComplete={onMainCellEditComplete} body={null} field="vvprop" style={{width: "5%"}}/>
                    <Column editor={vzpropEditor} onCellEditComplete={onMainCellEditComplete} body={null} field="vzprop" style={{width: "5%"}}/>
                    <Column editor={vvsvrEditor} onCellEditComplete={onMainCellEditComplete} body={null} field="vvsvr" style={{width: "5%"}}/>
                    <Column editor={vvsvoEditor} onCellEditComplete={onMainCellEditComplete} body={null} field="vvsvo" style={{width: "5%"}}/>
                    <Column editor={ivuspEditor} onCellEditComplete={onMainCellEditComplete} body={null} field="ivusp" style={{width: "5%"}}/>
                    <Column editor={ivpotEditor} onCellEditComplete={onMainCellEditComplete} body={null} field="ivpot" style={{width: "5%"}}/>
                    <Column editor={ivsvrEditor} onCellEditComplete={onMainCellEditComplete} body={null} field="ivsvr" style={{width: "5%"}}/>
                    <Column editor={ivsvoEditor} onCellEditComplete={onMainCellEditComplete} body={null} field="ivsvo" style={{width: "5%"}}/>
                    <Column body={deleteTableManager} header="" bodyStyle={{ textAlign: 'center'}} style={{ width: '2%', color: 'red' }}></Column>
                </DataTable>
            </Dialog>
        </div>
    )
}