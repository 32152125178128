import React, { useState } from "react";
import './index.scss'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";

export const ReportFinanceArenda = () => {
    const [date, setDate] = useState(null);

    const headerTable = () => {
        return (
            <div style={{padding: "1rem", display: "flex"}}>
                <Calendar value={date} onChange={(e) => setDate(e.value)} view="year" dateFormat="yy" style={{fontSize: "2rem"}} />
                <i className="pi pi-plus-circle" onClick={null} style={{marginLeft: "auto", fontSize: "2.5rem", cursor: "pointer"}}></i>
            </div>
        )
    }

    console.log(date);

    return (
        <>
            <DataTable header={headerTable} showGridlines stripedRows selectionMode="single" scrollable>
                <Column header="Месяц/Год"/>
                <Column header="Наличные"/>
                <Column header="Безналичные"/>
                <Column header="Итого"/>
                <Column header="План"/>
                <Column header="Выполнение"/>
                <Column header="Возвраты"/>
                <Column header=""/>
                <Column header=""/>
            </DataTable>
        </>
    )
}