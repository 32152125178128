import React from "react";
import './index.scss'
import { Route, Routes } from "react-router-dom";
import { ReportCompany } from "./company";
import { ReportInfo } from "./info";
import { ManagerStatistics } from "./statistics";
import { ReportFinance } from "./finance";
import { AnalizManager } from "./analizManager";

export const Reports = () => {
    return (
        <Routes>
            <Route path="fin" element={<ReportFinance/>}/>
            <Route path="company" element={<ReportCompany/>}/>
            <Route path={"statistics"} element={<ManagerStatistics/>} />
            <Route path="info" element={<ReportInfo/>}/>
            <Route path="analiz" element={<AnalizManager/>}/>
        </Routes>
    )
}