import React from "react";
import './index.scss'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export const ReportFinanceService = () => {

    const headerTable = () => {
        return (
            <div style={{padding: "1rem", display: "flex"}}>
                <i className="pi pi-plus-circle" onClick={null} style={{marginLeft: "auto", fontSize: "2.5rem", cursor: "pointer"}}></i>
            </div>
        )
    }

    return (
        <>
            <DataTable header={headerTable} showGridlines stripedRows selectionMode="single" scrollable>
                <Column header="Месяц/Год"/>
                <Column header="Кол-во ремонтов"/>
                <Column header="Затрачено"/>
                <Column header="Получено"/>
                <Column header="Страховки"/>
                <Column header="Разница"/>
                <Column header=""/>
                <Column header=""/>
            </DataTable>
        </>
    )
}