import React from "react";
import './index.scss'
import { ContentTop } from "../UI/ContentTop";
import { TabPanel, TabView } from "primereact/tabview";
import { ReportFinanceArenda } from "./financeArenda";
import { ReportFinanceService } from "./financeService";
import { ReportFinanceExpenses } from "./financeExpenses";
import { ReportFinanceDebts } from "./financeDebts";
import { ReportFinanceEmployer } from "./financeEmployer";

export const ReportFinance = () => {
    return (
        <div className="field">
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Фин. отчет</div>
                </div>
            </ContentTop>
            <TabView>
                <TabPanel header="Аренда"><ReportFinanceArenda/></TabPanel>
                <TabPanel header="Сервис"><ReportFinanceService/></TabPanel>
                <TabPanel header="Расходы"><ReportFinanceExpenses/></TabPanel>
                <TabPanel header="Долги"><ReportFinanceDebts/></TabPanel>
                <TabPanel header="Сотрудники"><ReportFinanceEmployer/></TabPanel>
            </TabView>
        </div>
    )
}