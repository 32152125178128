import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { deleteUser, editPasswordUser, editUser, getAllUsers, registr } from "../../http/userApi";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { useSelector } from "react-redux";

export const SettingsAccaunts = () => {
    const user = useSelector((state) => state.user.user);
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [visible, setVisible] = useState(false)
    const [name, setName] = useState("");
    const [login, setLogin] = useState("");
    const [pswd, setPswd] = useState("");
    const [role, setRole] = useState("MANAGER");
    const [position, setPosition] = useState("");
    const [status, setStatus] = useState("Работает");

    const [visible2, setVisible2] = useState(false)
    const [changePasswordId, setChangePasswordId] = useState(null)
    const [newPassword, setNewPassword] = useState(null)
    const toast = useRef(null);

    useEffect(() => {
        getAllUsers()
        .then((response) => {
          setItems(response.data);
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }, [loading])

    const createAccaunt = () => {
        setVisible(!visible)
    }

    const createItemButton = () => {
        try {
            if (!login || !pswd || !role || !position || !status || !name) {
              toast.current.show({severity:'error', summary: 'Ошибка', detail: "Введены не все данные", life: 3000});
              return;
            }
            registr(login, pswd, role, position, status, name).catch(e => alert(e.response.data.message));
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Аккаунт создан!", life: 3000});
          } catch (e) {
            alert(e);
          }
      
          setName("");
          setPswd("");
          setRole("");
          setLogin("");
          setPosition("");
          setStatus("");
          setVisible(!visible)
          setLoading(!loading);
    }

    const headerTable = (i) => {
        return (
            <div style={{padding: "1rem"}}>
                <Button onClick={createAccaunt} style={{background: "#4b4b4b", border: "none", fontSize: "1.4rem"}}>Добавить</Button>
            </div>
        )
    }

    const deleteTable = (i) => {
        return <i onClick={() => {
            const accept = () => {
                try {
                deleteUser(i.id)
                  setLoading(!loading)
                } catch (error) {
                  alert(error)
                }
              };
          
              const reject = () => {
                  return
              };
      
              confirmDialog({
                message: 'Вы действительно хотите удалить аккаунт?',
                header: 'Удаление',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                position: 'center',
                accept,
                reject
            });
              
        }} className="pi pi-trash" style={{ fontSize: '1.3rem' }}></i>
    }

    const allowEdit = (rowData) => {
        return rowData
    };

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;
       
        try {
            editUser(newData.id, newData.login, newData.role, newData.position, newData.status, newData.name)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Аккаунт изменен!", life: 3000});
            setLoading(!loading)
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: e.response.data.message, life: 3000});
        }
    };

    const loginEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const nameEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const roleEditor = (options) => {
        const arr = [{name: "ADMIN"}, {name: "MANAGER"}]
        return (
            <Dropdown
                value={options.value}
                options={arr.map(i => i.name)}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
            />
        );
    }

    const positionEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const statusEditor = (options) => {
        const arr = [{name: "Работает"}, {name: "Уволен"}]
        return (
            <Dropdown
                value={options.value}
                options={arr.map(i => i.name)}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
            />
        );
    }

    const changePassword = (i) => {
        return <i onClick={() => {
            setVisible2(!visible2)
            setChangePasswordId(i.id)
        }} class="pi pi-key" style={{fontSize: "1.8rem", color: "black"}}></i>
    }

    const applyChangePassword = () => {
        if(!newPassword) return
        try {
            editPasswordUser(changePasswordId, newPassword)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Пароль изменен!", life: 3000});
            setLoading(!loading)
        } catch (e) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: e.response.data.message, life: 3000});
        }
    }

    return (
        <>
        <Toast ref={toast} position="bottom-center"/>
        <ConfirmDialog />
        <DataTable editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} value={items} header={headerTable} showGridlines stripedRows selectionMode="single" scrollable tableStyle={{ minWidth: '45rem' }} emptyMessage="Пусто...">
            <Column editor={nameEditor} header="Имя" field="name" style={{width: "15%"}}/>
            <Column editor={(user.position === "Руководитель" || user.position === "Администратор") && loginEditor} header="Логин" field="login" style={{width: "15%"}}/>
            <Column editor={(user.position === "Руководитель" || user.position === "Администратор") && positionEditor} header="Должность" field="position" style={{width: ""}}/>
            <Column editor={(user.position === "Руководитель" || user.position === "Администратор") && roleEditor} header="Роль" field="role" style={{width: "10%"}}/>
            <Column editor={(user.position === "Руководитель" || user.position === "Администратор") && statusEditor} header="Статус" field="status" style={{width: "10%"}}/>
            {(user.position === "Руководитель" || user.position === "Администратор") && <Column body={changePassword} header="" bodyStyle={{ textAlign: 'center' }} style={{ width: '3%'}}></Column>}
            <Column rowEditor={allowEdit} headerStyle={{ width: '3%', minWidth: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
            {(user.position === "Руководитель" || user.position === "Администратор") && <Column body={deleteTable} header="" bodyStyle={{ textAlign: 'center' }} style={{ width: '3%', color: 'red' }}></Column>}
        </DataTable>

        <Dialog style={{ width: '32rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={visible} onHide={() => {if (!visible) return; setVisible(false); }}>
                <div className="create">
                    <div className="create-row">
                        <label htmlFor="name" className="font-bold">
                            Имя
                        </label>
                        <InputText value={name} onChange={e => setName(e.target.value)} id="name"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="login" className="font-bold">
                            Логин
                        </label>
                        <InputText value={login} onChange={e => setLogin(e.target.value)} id="login"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="pswd" className="font-bold">
                            Пароль
                        </label>
                        <InputText value={pswd} onChange={e => setPswd(e.target.value)} id="pswd"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="position" className="font-bold">
                            Должность
                        </label>
                        <InputText value={position} onChange={e => setPosition(e.target.value)} id="position"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="role" className="font-bold">
                            Роль
                        </label>
                        <Dropdown
                            value={role}
                            options={[{name: "ADMIN"}, {name: "MANAGER"}, {name: "CARMASTER"}].map(i => i.name)}
                            onChange={(e) => setRole(e.target.value)}
                            placeholder="Select a Role"
                            style={{width: "100%"}}
                        />
                    </div>

                    <div className="create-row">
                        <label htmlFor="status" className="font-bold">
                            Статус
                        </label>
                        <Dropdown
                            value={status}
                            options={[{name: "Работает"}, {name: "Уволен"}].map(i => i.name)}
                            onChange={(e) => setStatus(e.target.value)}
                            placeholder="Select a Role"
                            style={{width: "100%"}}
                        />
                    </div>

                    <Button onClick={createItemButton}>Добавить</Button>
                </div>
            </Dialog>

            <Dialog style={{ width: '32rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={visible2} onHide={() => {if (!visible2) return; setVisible2(false); setChangePasswordId(null) }}>
                <div className="create">
                    <div className="create-row">
                        <label htmlFor="newpassword" className="font-bold">
                            Новый пароль
                        </label>
                        <InputText value={newPassword} onChange={e => setNewPassword(e.target.value)} id="newpassword"></InputText>
                    </div>

                    <Button onClick={applyChangePassword}>Применить</Button>
                </div>
            </Dialog>
        </>
    )
}