import React, { useEffect, useRef, useState } from "react";
import './index.scss'
import { DataTable } from "primereact/datatable";
import { createEmployerApi, deleteEmployerApi, editEmployerApi, getAllEmployerApi } from "../../http/employer";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";

export const EmployerInfo = () => {
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const toast = useRef(null);

    useEffect(() => {
        getAllEmployerApi().then(res => setItems(res.data)).catch(e => alert(e)).finally(() => setLoading(false))
    }, [loading])

    const headerTable = () => {
        return (
            <div style={{padding: "1rem", display: "flex"}}>
                <i className="pi pi-user-plus" onClick={createItem} style={{marginLeft: "auto", fontSize: "2.5rem", cursor: "pointer"}}></i>
            </div>
        )
    }

    const createItem = () => {
        try {
            createEmployerApi(null, null, null, null, null, null)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Сотрудник добавлен!", life: 3000});
            setLoading(!loading)
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: error, life: 3000});
        }
    }

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;
        try {
            setItems(_items);
            editEmployerApi(newData.id, newData.name, newData.position, newData.shedule, newData.format, newData.salary, newData.status)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Сотрудник изменен!", life: 3000});
            setLoading(!loading)
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: e.response.data.message, life: 3000});
        }
    };

    const allowEdit = (rowData) => {
        return rowData
    };

    const deleteTable = (i) => {
        return <i onClick={() => {
            const accept = () => {
                try {
                   deleteEmployerApi(i.id)
                    setLoading(!loading)
                } catch (error) {
                    alert(error)
                }
                };

                const reject = () => {
                    return
                };

                confirmDialog({
                    message: 'Вы действительно хотите удалить сотрудника?',
                    header: 'Удаление',
                    icon: 'pi pi-info-circle',
                    defaultFocus: 'reject',
                    acceptClassName: 'p-button-danger',
                    position: 'center',
                    accept,
                    reject
                });

        }} className="pi pi-trash" style={{ fontSize: '1.3rem' }}></i>
    }

    const editor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    return (
        <>
            <Toast ref={toast} position="bottom-center"/>
            <ConfirmDialog/>
            <DataTable editMode="row" onRowEditComplete={onRowEditComplete} dataKey="id" value={items} header={headerTable} showGridlines stripedRows selectionMode="single" scrollable>
                <Column editor={editor} field="name" header="Имя"/>
                <Column editor={editor} field="position" header="Должность"/>
                <Column editor={editor} field="shedule" header="График"/>
                <Column editor={editor} field="format" header="Формат"/>
                <Column editor={editor} field="salary" header="ЗП"/>
                <Column editor={editor} field="status" header="Статус"/>
                <Column rowEditor={allowEdit} headerStyle={{ width: '3%', minWidth: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                <Column body={deleteTable} header="" bodyStyle={{ textAlign: 'center' }} style={{ width: '3%', color: 'red' }}></Column>
            </DataTable>
        </>
    )
}