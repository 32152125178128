import { $host } from "./index";

export const createAnalizCallCenterApi = async (date) => {
    const { data } = await $host.post("api/analiz-call-center", { date });
    return data;
};

export const getAllAnalizCallCenterApi = async (range) => {
    const data = await $host.get("api/analiz-call-center", {
        params: {
          range
        }
      });
    return data;
};

export const getAllAnalizManagersApi = async (analizcallcenterId) => {
    const data = await $host.get(`api/analiz-call-center/managers/${analizcallcenterId}`)
    return data
}

export const createAnalizCallCenterManagerApi = async (manager, vvprin, vvprop, vzprop, vvsvr, vvsvo, ivusp, ivpot, ivsvr, ivsvo, analizcallcenterId) => {
    const { data } = await $host.post("api/analiz-call-center/manager", { manager, vvprin, vvprop, vzprop, vvsvr, vvsvo, ivusp, ivpot, ivsvr, ivsvo, analizcallcenterId });
    return data;
};

export const deleteAnalizCallCenterManagerApi = async (id) => {
    const data = await $host.delete(`api/analiz-call-center/manager/${id}`);
    return data;
};

export const editAnalizCallCenterManagerApi = async (id, manager, vvprin, vvprop, vzprop, vvsvr, vvsvo, ivusp, ivpot, ivsvr, ivsvo) => {
    const data = await $host.put(`api/analiz-call-center/manager/${id}`, {manager, vvprin, vvprop, vzprop, vvsvr, vvsvo, ivusp, ivpot, ivsvr, ivsvo});
    return data;
};